* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #ecc2c2;
  --font_color: #b0b0ae;
  --font_color_d36985: #d36985;
  --color_1c1c1b: #1c1c1b;
  --bg_color_sub: #525151;
  --color_242423: #242423;
  --alert: #c22c2c;
  --white_color: #fff;
}

body {
  width: 100%;
  height: 100%;
  background-color: var(--color_1c1c1b);
  color: var(--font_color);
  letter-spacing: 1.2px;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  -webkit-font-feature-settings: "pkna" 1, "palt" 1;
  font-feature-settings: "pkna" 1, "palt" 1;
}

a {
  text-decoration: none;
}

.cont_wrapper {
  max-width: 640px;
  margin: 30px auto 100px;
}

.alert {
  color: var(--alert);
  font-size: 14px;
}

.outline_pink_btn {
  background-color: inherit;
  color: var(--font_color_d36985);
  border: 1px solid var(--font_color_d36985);
}

.outline_grey_btn {
  background-color: inherit;
  color: var(--bg_color_sub);
  border: 1px solid var(--bg_color_sub);
  cursor: none;
}

.normal_btn {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  border: none;
}

a.normal_btn,
a.outline_grey_btn,
a.outline_pink_btn {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  display: block;
}

button {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
}

button:disabled {
  opacity: 0.5;
}

select {
  padding-left: 15px;
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: var(--color_242423);
  color: var(--white_color);
  font-size: 16px;
  border: none;
  border-radius: 6px;
  outline: none;
}

.title {
  margin-bottom: 30px;
  text-align: center;
}

.noneStock {
  color: var(--font_color_d36985);
}

.required {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}

.any {
  background-color: var(--bg_color_sub);
  color: var(--white_color);
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}

.form-title {
  margin-bottom: 10px;
}

form {
  border-bottom: 1px solid var(--bg_color_sub);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

form>div {
  margin-bottom: 30px;
}

form>div:last-child {
  margin-bottom: 0;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid var(--bg_color_sub);
  background-color: var(--color_242423);
  padding: 0 10px;
  color: var(--font_color);
}

.StripeElement {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--bg_color_sub);
  background-color: var(--color_242423);
  padding: 10px;
  color: var(--font_color);
}

input[type="checkbox"] {
  display: none;
}

.login_option {
  text-align: center;
  margin-top: 40px;
}

.login_option a {
  color: var(--font_color_d36985);
}

.term {
  display: flex;
  align-items: center;
}

.term .terms_policy a {
  color: var(--font_color_d36985);
}

.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 7px;
}

.checkbox::before {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--white_color);
  border: 1px solid var(--font_color_d36985);
}

.checkbox::after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 15px;
  background-color: var(--font_color_d36985);
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

input[type="checkbox"]:checked+.checkbox::before {
  background-color: #d36985;
}

input[type="checkbox"]:checked+.checkbox::after {
  opacity: 1;
}

.button_flex {
  display: flex;
  justify-content: space-between;
}

.button_flex button,
.button_flex a {
  flex-basis: 49%;
}

.cont_wrapper {
  max-width: 640px;
  margin: 30px auto 100px;
}

#hamburger img {
  cursor: pointer;
}

.encas_cont_wrap {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 32px 104px 32px;
}

#header_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto 47px auto;
  border-bottom: 1px solid var(--bg_color_sub);
  padding: 30px 0;
}

.arrow {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin-right: 14px;
  margin-left: -10px;
}

.arrow::before {
  position: absolute;
  top: 8px;
  left: 10px;
  content: "";
  width: 10px;
  height: 10px;
  border-top: solid 2px var(--font_color);
  border-right: solid 2px var(--font_color);
}

.arrow.arrow-right::before {
  transform: rotate(45deg);
}

#header_sub .header_icon {
  width: 233px;
}

#header {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
}

.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: var(--main-color);
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right h1 {
  margin: 0 24px;
  font-size: 1.1rem;
}

.header-right .select_shop_pc {
  background-color: inherit;
  box-shadow: none;
  border: none;
  color: inherit;
  padding: 0;
  transition: all .3s;
  margin-right: 10px;
  width: 60px;
  height: 60px;
}

.header-right .select_shop_pc svg {
  width: 30px;
  height: 30px;
}

.header-right .select_shop_pc:hover,
.header-right .select_shop_pc.active {
  color: var(--main-color);
}


.select_shop_sp {
  display: none;
}

#shopListModal {
  background: var(--color_242423);
  height: 0;
  width: 300px;
  position: absolute;
  border-radius: 6px;
  right: 50px;
  transition: all .3s;
  overflow-y: auto;
  z-index: 999999;
}

#shopListModal.active {
  padding: 10px;
  height: 50vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
}

#shopListModal .shopListNoItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#shopListModal .shopListItem {
  margin: 5px;
  padding: 5px;
}

#shopListModal .shopListItem {
  list-style: none;
  border-bottom: 2px solid var(--bg_color_sub);

  cursor: pointer;
}

.form_post_flex {
  display: flex;
  align-items: center;
  width: fit-content;
}

.form_post_flex button {
  max-width: 120px;
  margin-left: 20px;
  padding: 10px;
}

#header .header_icon {
  width: 180px;
}

#hamburger {
  width: 64px;
  height: 64px;
  z-index: 1000;
}

.menu_icon {
  display: block;
  width: 100%;
  height: 100%;
}

.close {
  display: block;
  width: 100%;
  height: 100%;
}

.menu {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 590px;
  background-color: var(--color_1c1c1b);
  z-index: 750;
}

.hidden {
  display: none;
}

.menu_wrapper {
  margin-top: 120px;
  margin-left: 101px;
  width: 400px;
  height: 484px;
}

.menu_wrapper ul li {
  list-style: none;
  height: 20px;
  margin-bottom: 20px;
}

.menu_wrapper ul li:nth-of-type(1) {
  margin-top: 35px;
}

.menu_wrapper ul a,
.menu_wrapper ul p {
  text-decoration: none;
  color: var(--font_color);
  font-size: 20px;
  transition: all 0.3s;
  cursor: pointer;
}

.menu_wrapper ul a:hover,
.menu_wrapper ul p:hover {
  opacity: 0.7;
}

.button_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  background-color: var(--color_242423);
}

.edit_button svg {
  /* width: 75px; */
  height: 60px;
  fill: var(--font_color);
}

.edit_button p {
  text-decoration: none;
  color: var(--font_color);
  font-size: 17px;
  text-align: center;
}

.mailComplete {
  text-align: center;
}

.guide {
  margin-bottom: 30px;
}

.passReset {
  margin-bottom: 10px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            店舗側 商品一覧画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.product_top_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #525151;
  padding-bottom: 30px;
}

.select_wrap {
  position: relative;
  width: 160px;
  height: 52px;
  border: 1px solid #525151;
  border-radius: 6px;
  cursor: pointer;
}

.select_wrap::before {
  content: "";
  position: absolute;
  right: 16px;
  top: 22px;
  z-index: 100;
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--font_color);
  border-left: 2px solid var(--font_color);
  transform: translateY(-50%) rotate(-135deg);
}

.product_top_buttons .category_button {
  width: 130px;
  height: 52px;
  text-align: center;
  background-color: #1c1c1b;
  font-size: 16px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 8px;
}

.product_top_buttons select,
.product_top_buttons .category_button {
  cursor: pointer;
}

.product_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 326px;
  border-bottom: 2px solid #525151;
}

.product_item_img {
  min-width: 260px;
  width: 260px;
  height: 260px;
  border: 2px solid #525151;
}

.product_item_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_item_info {
  margin-left: 24px;
  height: 260px;
}

.product_item_buttons {
  width: 100%;
  margin-top: 20px;
}

.product_edit_button,
.product_delete_button {
  width: 80px;
  font-size: 16px;
  border-radius: 6px;
  padding: 7px;
}

.product_edit_button {
  margin-right: 16px;
  color: #1c1c1b;
  border: 1px solid #525151;
  text-align: center;
}

.product_item_title {
  margin-top: 16px;
  color: var(--white_color);
  font-size: 18px;
}

.product_item_detail {
  margin-top: 20px;
  font-size: 16px;
}

.product_stock {
  margin-top: 12px;
  text-align: right;
  font-size: 16px;
}

.product_price {
  text-align: right;
  font-size: 16px;
}

.product_stock span,
.product_price span {
  font-size: 20px;
}

.change_page_buttons {
  display: flex;
  justify-content: center;
  margin: 50px auto 100px auto;
  height: 50px;
}

.page_arrow {
  position: relative;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}

.page_arrow::before {
  content: "";
  width: 14px;
  height: 14px;
  border-top: solid 2px var(--main-color);
  border-right: solid 2px var(--main-color);
  position: absolute;
  left: 2px;
  top: 16px;
}

.page_arrow.arrow_left::before {
  transform: rotate(-135deg);
}

.page_arrow.arrow_right::before {
  transform: rotate(45deg);
}

.page_button {
  margin: 0 8px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--main-color);
  cursor: pointer;
}

.page_button p {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
  color: var(--main-color);
  font-size: 20px;
}

.page_1 {
  background-color: var(--main-color);
}

.page_1 p {
  color: var(--color_1c1c1b);
}

.page_dot {
  width: 50px;
  height: 50px;
}

.page_dot img {
  display: block;
  margin: 10px auto;
  width: 30px;
  height: 30px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            ダイアログ画面
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
/* ダイアログ */
#dialog {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
}

.dialog_box {
  background: var(--color_1c1c1b);
  width: 450px;
  padding: 15px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

.dialog_space {
  margin-bottom: 50px;
}

.dialogText {
  text-align: center;
}

.dialog_inner {
  padding: 30px;
}

#completeDialog,
#failureDialog {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

#completeDialog img,
#failureDialog img {
  width: 100px;
}

#completeDialog p {
  margin-bottom: 50px;
}

#failureDialog h3 {
  margin-bottom: 20px;
}

#failureDialog p {
  margin-bottom: 40px;
}

#logout p {
  margin: 60px;
  font-size: 20px;
  text-align: center;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            注文ダイアログ画面
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.item_img {
  margin: 30px auto 0 auto;
  min-width: 160px;
  width: 160px;
  height: 160px;
  border: 2px solid #525151;
}

.item_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item_title {
  margin-top: 24px;
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}

.item_detail {
  margin-top: 16px;
  color: #b0b0ae;
  font-size: 16px;
}

.stock {
  margin-top: 8px;
  text-align: right;
  color: #b0b0ae;
  font-size: 16px;
}

.stock span {
  font-size: 20px;
}

.price {
  margin-top: 8px;
  text-align: right;
  color: #b0b0ae;
  font-size: 16px;
}

.price span {
  font-size: 20px;
}

.total_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  height: 52px;
}

.total_price {
  line-height: 52px;
  color: #b0b0ae;
  font-size: 18px;
}

.total_price span:nth-of-type(1) {
  color: #fff;
  font-size: 24px;
}

.total_price span:nth-of-type(2) {
  color: #fff;
}

.count_wrap {
  display: flex;
  align-items: center;
}

.order_count {
  margin-right: 12px;
  color: #b0b0ae;
  font-size: 18px;
}

#orderDialog .select_wrap {
  position: relative;
  width: 120px;
  height: 100%;
  border: 1px solid #525151;
  border-radius: 6px;
  cursor: pointer;
}

#orderDialog .select_wrap::before {
  content: "";
  position: absolute;
  right: 16px;
  top: 22px;
  z-index: 100;
  width: 8px;
  height: 8px;
  border-top: 2px solid #b0b0ae;
  border-left: 2px solid #b0b0ae;
  transform: translateY(-50%) rotate(-135deg);
}

.total_wrap select {
  padding-left: 15px;
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: #242423;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

.message {
  margin-top: 16px;
  color: #b0b0ae;
  font-size: 16px;
}

.message_text {
  margin-top: 8px;
  padding: 8px;
  width: 100%;
  height: 160px;
  color: #fff;
  font-size: 16px;
  background-color: #242423;
  border: 2px solid #525151;
  border-radius: 4px;
  outline: none;
}

.quantity_text {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
}

input[type="text"].quantity_input {
  width: 50px;
}

.gray_line {
  margin-top: 30px;
  width: 100%;
  height: 2px;
  background-color: #525151;
}

.check_area {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
}

.check_area input[type="radio"] {
  display: none;
}

.none_label {
  position: relative;
  padding: 0 0 0 50px;
}

.none_label:after,
.none_label:before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
}

.none_label:after {
  left: 10px;
  margin-top: -16px;
  width: 30px;
  height: 30px;
  border: 2px solid #525151;
  border-radius: 50%;
  cursor: pointer;
}

.none_label:before {
  left: 17px;
  margin-top: -9px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
}

.check_area input[type="radio"]:checked+.none_label:before {
  opacity: 1;
}

.paypay,
.linepay {
  display: flex;
}

.paypay img,
.linepay img {
  height: 30px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            ローディング画面
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
@keyframes ldio-yr7rj8lblh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#loading {
  position: fixed;
  z-index: 999999999;
  background: rgb(0 0 0 / 60%);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.testButton {
  position: relative;
  z-index: 9999999;
}

.ldio-yr7rj8lblh div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 7.78px solid #ecc2c2;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-yr7rj8lblh div {
  animation: ldio-yr7rj8lblh 1s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingio-spinner-rolling-09ckrq18eb7s {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}

.ldio-yr7rj8lblh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yr7rj8lblh div {
  box-sizing: content-box;
}

#error {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#error h3 {
  margin-bottom: 30px;
  min-width: 300px;
}

#payComplete {
  height: 70vh;
  position: relative;
}

#policy .logo {
  text-align: center;
  padding: 20px 0 60px;
}

#policy.cont_wrapper {
  margin-bottom: 0;
}

#policy .top_detail {
  margin-bottom: 20px;
}

#policy .logo img {
  width: 180px;
}

#policy p {
  line-height: 2.2;
}

#payComplete>div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

footer {
  text-align: center;
}

footer a {
  color: var(--font_color);
  display: block;
  margin: 15px 0;
}

#tokushoho table {
  margin-bottom: 60px;
}

#tokushoho table tr th {
  padding-right: 5px;
  min-width: 60px;
}

#tokushoho table tr td {
  padding: 10px 5px;
}

#payComplete h3 {
  margin-bottom: 30px;
}

.table {
  margin-bottom: 20px;
  width: 100%;
  background-color: #1c1c1b;
  text-align: center;
  color: #b0b0ae;
  font-size: 16px;
  border-collapse: collapse;
  border-bottom: 2px solid #525151;
}

.table tr {
  height: 80px;
}

.table tr:nth-child(odd) td {
  background-color: var(--color_242423);
}

.table th {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  font-weight: normal;
}

.table td,
.table th {
  border-left: 2px solid var(--bg_color_sub);
  border-top: 2px solid var(--bg_color_sub);
  padding: 5px;
}

.table td:first-child,
.table th:first-child {
  border-left: none;
}

.table tr:first-child td,
.table tr:first-child th {
  border-top: none;
}

.sampleProduct td {
  padding: 20px 10px;
}

.table.sampleProduct td.sampleImg {
  padding: 0;
  background: #fff;
}

.sampleProduct td img {
  height: 100px;
}

.sampleProduct td p {
  text-align: initial;
}

.sampleProductWrapper .table_wrap {
  margin-bottom: 50px;
}

#passResetCompleted {
  text-align: center;
}

#passResetCompleted p {
  margin-bottom: 50px;
}

#passResetCompleted .button_center {
  max-width: 350px;
  margin: 0 auto;
}

#stripeDialog .form_item {
  margin-bottom: 25px;
}

#stripeDialog .gray_line {
  margin-bottom: 30px;
}

.available_cards {
  background-color: var(--bg_color_sub);
  border: 1px solid var(--font_color);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.available_cards p {
  margin-bottom: 5px;
}

.cards_icon {
  display: flex;
  align-items: center;
}

.cards_icon img {
  width: 40px;
  margin-right: 10px;
}

.cards_icon img.amex {
  width: 29px;
}

.top_wrapper {
  margin: 130px auto;
  max-width: 640px;
}

.top_wrapper h2 {
  font-size: 17px;
  color: var(--main-color);
  text-align: center;
}

.top_wrapper .store_top_icon {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.top_wrapper h3 {
  color: var(--main-color);
  margin-bottom: 20px;
}

.text_wrapper {
  margin-bottom: 40px;
}

.top_text_flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.top_text_flex p {
  max-width: 95%;
}

.pink_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin-right: 10px;
}

@media (max-width: 750px) {
  .sampleProduct td p {
    word-wrap: break-word;
    white-space: break-spaces;
    width: 230px;
    line-height: 1.8;
  }

  .sampleProduct td.sampleDetail {
    max-width: 200px;
  }

  .cont_wrapper.sampleProductWrapper {
    overflow: scroll;
    margin: auto 0px auto 0px;
    max-width: 100%;
  }

  .table_wrap {
    overflow-x: scroll;
    margin-right: calc((100% - 90%) / 2 * -1);
    padding-right: calc((100% - 90%) / 2);
    margin-left: calc((100% - 90%) / 2 * -1);
    padding-left: calc((100% - 90%) / 2);
  }

  #tokushoho .table {
    white-space: normal;
  }

  .table {
    white-space: nowrap;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .table tr {
    height: 40px;
  }

  #header_sub {
    padding: 20px 0;
  }

  #header_sub .header_icon {
    width: 116px;
  }

  #header .header_icon {
    width: 85px;
  }

  .header-center {
    max-width: 139px;
  }

  #hamburger {
    width: 45px;
    height: 45px;
  }

  .header-center p {
    font-size: 1.1rem;
  }

  .header-right h1 {
    margin: 0 10px;
  }

  .header-right .select_shop_pc {
    display: none;
  }

  .select_shop_sp {
    display: block;
    background-color: var(--main-color);
    color: var(--color_1c1c1b);
    text-align: center;
    border: none;
    border-radius: inherit;
    padding: 5px 0 2px;
  }

  .select_shop_sp .icon_shop {

    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .select_shop_sp .icon_arrow_bottom {
    height: 20px;
    width: 20px;
    /* color: var(--white_color); */
    /* background-color: var(--font_color); */
    /* border-radius: 50%; */
    transition: all .3s;
  }

  .select_shop_sp .icon_arrow_bottom.active {
    transform: rotate(180deg);
  }

  #shopListModal {
    width: 90%;
    left: 50%;
    transform: translate(-50%, 10px);
  }

  #shopListModal.active {
    height: 75vh;
  }

  .menu {
    width: 240px;
    height: 540px;
  }

  .menu_wrapper {
    width: 100%;
    height: 480px;
    margin-top: 80px;
    margin-left: 0;
  }

  .button_area {
    height: 90px;
  }

  .edit_button svg {
    height: 40px;
  }

  .arrow {
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .arrow::before {
    top: 10px;
    left: 10px;
    width: 8px;
    height: 8px;
    border-top: solid 2px var(--font_color);
    border-right: solid 2px var(--font_color);
  }

  .menu_wrapper ul li {
    height: 14px;
    margin-bottom: 28px;
  }

  .menu_wrapper ul li:nth-of-type(1) {
    margin-top: 28px;
  }

  .menu_wrapper ul a,
  .menu_wrapper ul p {
    font-size: 14px;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
              店舗側 商品一覧画面(SP)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  .product_top_buttons {
    height: 80px;
  }

  .select_wrap {
    width: 130px;
    height: 40px;
    border-radius: 4px;
  }

  .select_wrap::before {
    top: 17px;
  }

  .product_top_buttons select {
    padding-left: 15px;
    font-size: 8px;
    border-radius: 4px;
  }

  .product_top_buttons .category_button {
    width: 86px;
    height: 40px;
    font-size: 8px;
    border-radius: 4px;
  }

  .product_item {
    min-height: 224px;
  }

  .product_item_img {
    min-width: 171px;
    width: 171px;
    height: 171px;
    border: 1px solid #525151;
  }

  .product_item_info {
    margin-left: 8px;
    height: auto;
  }

  .product_item_buttons button {
    font-size: 8px;
    padding: 10px;
  }

  .product_edit_button {
    margin-right: 8px;
  }

  .product_item_title {
    margin-top: 8px;
    font-size: 12px;
  }

  .product_item_detail {
    margin-top: 8px;
    font-size: 10px;
  }

  .product_stock {
    margin-top: 8px;
    font-size: 10px;
  }

  .product_price {
    margin-top: 4px;
    font-size: 10px;
  }

  .product_stock span,
  .product_price span {
    font-size: 12px;
  }

  .change_page_buttons {
    margin: 40px auto 75px auto;
    height: 40px;
  }

  .page_arrow {
    padding: 10px;
  }

  .page_arrow::before {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 16px;
  }

  .page_button {
    margin: 0 6px;
    width: 40px;
    height: 40px;
  }

  .page_button p {
    line-height: 40px;
    font-size: 14px;
  }

  .page_dot {
    width: 30px;
    height: 40px;
  }

  .page_dot img {
    width: 20px;
    height: 20px;
  }

  .item_img {
    border: 1px solid #525151;
  }

  .item_title {
    font-size: 12px;
  }

  .item_detail {
    margin-top: 12px;
    font-size: 12px;
  }

  .stock {
    margin-top: 6px;
    font-size: 12px;
  }

  .stock span {
    font-size: 14px;
  }

  .price {
    margin-top: 6px;
    font-size: 10px;
  }

  .price span {
    font-size: 14px;
  }

  .total_wrap {
    margin-top: 12px;
    height: 40px;
  }

  .total_price {
    line-height: 40px;
    font-size: 12px;
  }

  .total_price span:nth-of-type(1) {
    font-size: 14px;
  }

  .total_price span:nth-of-type(2) {
    font-size: 10px;
  }

  .order_count {
    margin-right: 8px;
    font-size: 12px;
  }

  #orderDialog .select_wrap {
    width: 80px;
    border-radius: 4px;
  }

  #orderDialog .select_wrap::before {
    right: 12px;
    top: 18px;
    width: 6px;
    height: 6px;
  }

  .total_wrap select {
    font-size: 14px;
    border-radius: 6px;
  }

  .message {
    margin-top: 8px;
    font-size: 12px;
  }

  .message_text {
    padding: 4px;
    height: 150px;
    font-size: 14px;
    border: 1px solid #525151;
  }

  .gray_line {
    margin-top: 24px;
    height: 1px;
  }

  .check_area {
    margin-top: 24px;
    height: 36px;
  }

  .none_label:after {
    border: 1px solid #525151;
  }

  .none_label:before {
    left: 17px;
    margin-top: -9px;
    width: 18px;
    height: 18px;
  }

  .top_wrapper {
    margin: 130px 24px;
    max-width: auto;
  }

  .top_wrapper h3 {
    text-align: center;
  }
}

@media (max-width: 480px) {

  #tokushoho table tr th,
  #tokushoho table tr td {
    display: block;
  }

  #tokushoho table tr td {
    border-right: 2px solid var(--bg_color_sub);
    background-color: var(--color_242423);
  }

  .cont_wrapper {
    max-width: 90%;
  }

  .form_checkbox_flex input[type="text"] {
    width: 70%;
  }

  .dialog_box {
    width: 350px;
  }

  #failureDialog {
    width: 80%;
  }
}